import {
  IBlogPostPreviewRawData,
  IBlogPostPreview,
} from "../prismic-types/blog-page"

type FormatDataForBlogPostPreview = (
  rawData: IBlogPostPreviewRawData
) => IBlogPostPreview[]

/**
 * Formats data for blog post preview component
 * @param rawData
 * @returns an array of objects as IBlogPostPreview
 */
export const formatDataForBlogPostPreview: FormatDataForBlogPostPreview = (
  rawData
) =>
  rawData.edges.map((blogPost) => {
    const {
      title: postTitle,
      author_avatar: authorAvatar,
      author,
      publish_date: publishDate,
      post_summary: postSummary,
      image,
    } = blogPost.node.data

    return {
      title: postTitle.text,
      blogPostMetaData: {
        authorAvatar: {
          url: authorAvatar.url,
          alt: authorAvatar.alt,
        },
        author,
        publishDate,
      },
      previewText: postSummary.html,
      url: `/blog/${blogPost.node.uid}`,
      previewImage: {
        url: image.fluid,
        alt: image.alt,
      },
    }
  })
