import React, { useState } from "react"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "styled-components"

// Styles
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"

// Component
import BlogPostMetaData from "./BlogPostMetaData"
import Button from "./atoms/Button"
import RequestDemo from "./RequestDemo"

// Types
import { IBlogPostPreview } from "../prismic-types/blog-page"
import { IDemoPage } from "../prismic-types/request-demo-page"

// Hooks
import { useLocale } from "../hooks/useLocale"

interface IProps extends IBlogPostPreview {
  demoPage: IDemoPage
}
const BlogPostPreview: React.FCS<IProps> = ({
  title,
  blogPostMetaData,
  previewText,
  url,
  previewImage,
  className,
  demoPage,
}) => {
  const [requestedResource, setRequestedResource] = useState<string>()
  const { getLocalizedLink, isDutch } = useLocale()
  return (
    <Container className={className}>
      {previewImage.url && (
        <StyledGatsbyMobileImage
          fluid={previewImage.url}
          alt={previewImage.alt}
        />
      )}
      {title && <TitleMobile>{title}</TitleMobile>}
      <StyledBlogPostMetaData {...blogPostMetaData} dateFirstOnMobile />
      <OuterWrapper>
        <InnerWrapper>
          {title && <TitleDesktop>{title}</TitleDesktop>}
          <Paragraph dangerouslySetInnerHTML={{ __html: previewText }} />
          <Buttons>
            <Button
              href={getLocalizedLink(url)}
              label={isDutch ? "Lees verder" : "Read the post"}
              variant="secondary"
            />
          </Buttons>
        </InnerWrapper>
        {previewImage.url && (
          <StyledGatsbyDesktopImage
            fluid={previewImage.url}
            alt={previewImage.alt}
          />
        )}
      </OuterWrapper>
      {requestedResource && (
        <RequestDemo
          title={demoPage.downloadTitle}
          text={demoPage.downloadText}
          variant="download"
          labels={demoPage.labels}
          download={requestedResource}
          closeModal={() => setRequestedResource(undefined)}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.whitePurple};

  &:last-child {
    margin-bottom: 124px;
  }

  ${mq.from.S`
    margin-bottom: 50px;
    padding-bottom: 50px;
  `}

  ${mq.from.M`
    display: flex;
    padding-bottom: 0;
    border: none;
  `}
`

const StyledGatsbyMobileImage = styled(GatsbyImage)<{ fluid: FluidObject[] }>`
  margin-bottom: 24px;

  ${mq.from.S`
    margin-bottom: 40px;
  `}

  ${mq.from.M`
    display: none;
  `}
`

const StyledGatsbyDesktopImage = styled(GatsbyImage)<{
  fluid: FluidObject[]
  first?: boolean
}>`
  ${mq.to.M`
    display: none;
  `}

  width : calc(50% - 24px);
  height: 100%;
  align-self: center;
  order: 2;

  img {
    margin: 0;
  }
`

const TitleMobile = styled.h2`
  color: ${colors.main["100"]};
  ${textStyles.titleM};

  ${mq.from.M`
    display: none;
  `}
`

const TitleDesktop = styled.h5`
  color: ${colors.main["100"]};
  ${mq.to.M`
    display: none;
  `}

  ${mq.from.M`
    font-size: 22px !important;
    font-weight: 500 !important;
  `}

  ${textStyles.titleS};
  margin-bottom: 20px;
`

const OuterWrapper = styled.div`
  ${mq.from.M`
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    width: 100%;
    align-items: flex-start;
    padding-bottom: 50px;
    border-bottom: 1px solid ${colors.whitePurple};
  `}
`

const InnerWrapper = styled.div`
  ${mq.from.M`
    width: 50%;
    order: 1;
  `}
`

const StyledBlogPostMetaData = styled(BlogPostMetaData)`
  margin-bottom: 18px;

  ${mq.from.M`
    flex-shrink: 0;
    margin-right: 24px;
    width: 17%;
  `}
`

const Paragraph = styled.div`
  color: ${colors.main["400"]};
  p {
    ${textStyles.quote};
    font-size: 16px !important;
  }

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ${mq.from.S`
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  `}

  ${mq.from.M`
  p {
    font-size: 18px !important;
  }
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  `}
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > a {
    margin-right: 16px;
  }
`

export default BlogPostPreview
