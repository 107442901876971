import React from "react"
import styled from "styled-components"

// Utils
import formatDate from "../utils/dateHelper"

// Styles
import colors from "../styles/colors"
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"

// Components
import bartAvatar from "../images/bart-ter-steege.png"

// Types
import { IBlogPostMetaData } from "../prismic-types/templates/blog-post-page"

// Constants
const DEFAULT_AUTHOR_NAME = "Bart ter Steege"
interface IProps extends IBlogPostMetaData {
  dateFirstOnMobile?: boolean
}

const BlogPostMetaData: React.FCS<IProps> = ({
  authorAvatar,
  author,
  publishDate,
  dateFirstOnMobile,
  className,
}) => {
  const formattedPublishDate = formatDate(publishDate)
  const avatarImg = {
    url: authorAvatar.url ?? bartAvatar,
    alt: authorAvatar.alt ?? DEFAULT_AUTHOR_NAME,
  }
  const authorName = author ?? DEFAULT_AUTHOR_NAME

  return (
    <Container className={className}>
      {avatarImg.url && (
        <AuthorWrapper>
          <Avatar src={avatarImg.url} alt={avatarImg.alt} />
          <Author>{authorName}</Author>
        </AuthorWrapper>
      )}
      {publishDate && formattedPublishDate && (
        <PublishDate
          itemProp="datePublished"
          dateTime={publishDate}
          title={formattedPublishDate}
          dateFirstOnMobile={dateFirstOnMobile}
        >
          {formattedPublishDate}
        </PublishDate>
      )}
    </Container>
  )
}

const Container = styled.div`
  flex-direction: column;
  display: flex;
`

const AuthorWrapper = styled.div`
  align-items: center;
  order: 2;
  display: flex;
  margin-bottom: 4px;

  ${mq.from.M`
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
  `}
`

const Avatar = styled.img`
  border-radius: 50%;
  margin-right: 12px;
  margin-bottom: 0;
  width: 60px;
  height: 60px;

  ${mq.from.M`
    margin-right: 0;
    margin-bottom: 12px;
    width: 80px;
    height: 80px;
  `}
`

const Author = styled.span`
  color: ${colors.main["200"]};
  ${textStyles.bodyBold};
`

const PublishDate = styled.time<{ dateFirstOnMobile?: boolean }>`
  ${textStyles.caption}
  order: 3;
  color: ${colors.purple};
  ${({ dateFirstOnMobile }) =>
    dateFirstOnMobile &&
    `
      order: 1;
      margin-bottom: 18px;
    `}

  ${mq.from.M`
    order: 3;
  `}
`

export default BlogPostMetaData
