import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Utils
import { formatDataForBlogPostPreview } from "../utils/dataHelpers"

// Styles
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"

// Components
import Seo from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import BlogPostPreview from "../components/BlogPostPreview"

// Types
import { IBlogPage, IBlogPostPreviewRawData } from "../prismic-types/blog-page"
import { IDemoPage } from "../prismic-types/request-demo-page"

interface IProps {
  content: IBlogPage
  demoPage: IDemoPage
}

const BlogPage: React.FC<IProps> = ({ content, demoPage }) => {
  const seo = {
    title: content.meta.title,
    description: content.meta.description,
    lang: content.meta.lang,
    datePublished: content.meta.publishedDate,
    dateModified: content.meta.modifiedDate,
    slug: "blog",
  }

  return (
    <>
      <Seo
        title={seo.title}
        description={seo.description}
        dateModified={seo.dateModified}
        datePublished={seo.datePublished}
        lang={seo.lang}
        slug={seo.slug}
        breadCrumbItems={[{ name: "Blogs", url: seo.slug }]}
      />
      <StyledContentWrapper>
        {content.title && <Title>{content.title}</Title>}
        {content.blogPostPreviews.map((blogPostPreview) => (
          <BlogPostPreview
            key={blogPostPreview.url}
            {...blogPostPreview}
            demoPage={demoPage}
          />
        ))}
      </StyledContentWrapper>
    </>
  )
}

const StyledContentWrapper = styled(ContentWrapper)`
  padding-top: 100px;

  ${mq.from.M`
    padding-top: 160px;
  `}

  ${mq.from.L`
    padding-top: 200px;
  `}
`

const Title = styled.h2`
  color: ${colors.main["100"]};
  ${textStyles.titleL}
  margin-bottom: 64px;

  ${mq.from.M`
    margin-bottom: 48px;
    font-size: 40px !important;
  `}
`

export const query = graphql`
  query blogPage($locale: String!) {
    prismicBlogPage(lang: { eq: $locale }) {
      last_publication_date
      lang
      first_publication_date
      data {
        meta_title
        meta_description
        main_title {
          text
        }
      }
    }

    prismicRequestADemoPage(lang: { eq: $locale }) {
      last_publication_date
      lang
      first_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Page
        title {
          text
        }
        text {
          html
        }
        download_title {
          text
        }
        download_text {
          html
        }
        name
        notes
        phone
        email
        company
        interest
        employees
      }
    }

    allPrismicBlogPostPage(
      sort: { fields: data___publish_date, order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            author_avatar {
              url
              alt
            }
            author
            publish_date
            post_summary {
              html
            }
            image {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
              url
              alt
            }
          }
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const raw = data.prismicBlogPage.data
  const rawBlogPostsData: IBlogPostPreviewRawData = data.allPrismicBlogPostPage
  const { data: demoPageData } = data.prismicRequestADemoPage

  const publishedDate = data.prismicBlogPage.first_publication_date
  const modifiedDate = data.prismicBlogPage.last_publication_date
  const lang = data.prismicBlogPage.lang

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: data.prismicBlogPage.first_publication_date,
    modifiedDate: data.prismicBlogPage.last_publication_date,
    lang: data.prismicBlogPage.lang,
  }
  const title = raw.main_title.text

  const blogPostPreviews = formatDataForBlogPostPreview(rawBlogPostsData)

  const demoPage: IDemoPage = {
    title: demoPageData.title.text,
    text: demoPageData.text.html,
    downloadTitle: demoPageData.download_title.text,
    downloadText: demoPageData.download_text.html,
    labels: {
      name: demoPageData.name,
      email: demoPageData.email,
      phone: demoPageData.phone,
      company: demoPageData.company,
      notes: demoPageData.notes,
      employees: demoPageData.employees,
      interest: demoPageData.interest,
    },
  }

  const content = {
    meta,
    publishedDate,
    modifiedDate,
    lang,
    title,
    blogPostPreviews,
  }

  return <BlogPage content={content} demoPage={demoPage} />
}
